import React from 'react'; // eslint-disable-line no-unused-vars
import { Paper, Stack, Typography } from '@mui/material';
import Layout from '../components/Layout';
import { Title } from '../components/Texts';

const texts = [
  {
    a: 'Plates-formes collaboratives de collecte, d’exploration, d’analyses et de traitements de données spatio-temporelles',
    b: 'Collectez, combinez, explorez et analysez des données massives pour comprendre et décider.',
    color: 'primary.light',
  },
  {
    a: 'Conception de serveurs cartographiques privés sur mesure',
    b: 'Combinez des données publiques (IGN, OpenStreetMap…) et vos données internes pour les visualiser et les analyser interactivement en préservant leur confidentialité.',
    color: 'primary.light',
  },
  {
    a: 'Création automatisée de cartes vectorielles à partir d’images',
    b: 'Transformez en cartes interactives des photos de cartes anciennes, de campus, de chantiers…',
    color: 'primary.light',
  },
  {
    a: 'Traitement et analyse de données multidimensionnelles de mouvements et trajectoires (Smartphone, GPS, AIS…)',
    b: 'Analysez le comportement de chalands, les déplacements au sein d’une zone, les moyens de transport…',
    color: 'primary.light',
  },
  {
    a: 'Caractérisation du tissu économique d’une zone',
    b: 'Analysez les activités des commerces et des entreprises. Favorisez un développement économique en ligne avec vos objectifs stratégiques.',
    color: 'primary.light',
  },
  {
    a: 'Applications pour l’Aménagement de territoires',
    b: 'Obtenez une connaissance approfondie de votre territoire, réalisez des simulations…',
    color: 'primary.light',
  },
  {
    a: 'Sécurité informatique',
    b: 'Auditez vos applications en ligne, quantifiez et minimisez votre exposition à des attaques…',
    color: 'primary.light',
  },
];

const Experts = () => (
  <Layout title="Expertises">
    <Title>
      Expertises
    </Title>

    <Stack spacing={3} sx={{ mt: 4 }}>
      {texts.map(({ a, b }) => (
        <Paper
          sx={{
            px: 4,
            py: 2,
            bgcolor: '#425e7b',
            color: '#fff',
          }}
          key={a + b}
        >
          {a && (<Typography variant="body1" sx={{ fontWeight: 'bold' }}>{a}</Typography>)}
          {b && <Typography variant="body2">{b}</Typography>}
        </Paper>
      ))}
    </Stack>
  </Layout>
);

export default Experts;
